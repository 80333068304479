import Navbar from "../components/Navbar";
import Footer from "../components/Footer";


const withLayout = (BaseComponent = {}) => (props) => {
  return (
    <div style={{minHeight: "100vh"}}>
      <Navbar />      
        <BaseComponent {...props} />
      <Footer />
    </div>
  );
};

export { withLayout };