import React from "react";

class Footer extends React.Component {
    constructor(props) {
      super(props);
  
    }
  
  render(){
  
    return(
      <div className="footer-basic">
        <footer>
        <div>&nbsp;&nbsp;
          <a href="#">FAQ</a>&nbsp;&nbsp;
          <a href="/privacypolicy.pdf" target="_blank">PRIVACY</a>&nbsp;&nbsp;
          <a href="mailto:hi@gosk.ai">Contact us</a>
        </div>
          <a href="https://www.linkedin.com/company/gosk" target="blank_">
            <img className="social_logo" src="/img/linkedin.png" alt="Linkedin Logo" />
          </a>
          <a href="https://twitter.com/Gosk_ai" target="blank_">
            <img className="social_logo" src="/img/twitter.png" alt="Twitter Logo" />
          </a>
          {/* <div className="social"><a href="#"><i className="icon ion-social-linkedin"></i></a><a href="#"><i className="icon ion-social-twitter"></i></a></div> */}
          <p className="copyright">GOSK ENTERPRISES PTE LTD | Singapore</p>
        </footer>
      </div>
    )
  }
  
  }
  
  
  export default (Footer);




