import React from "react";
import { withLayout } from '../wrappers/layout';

class IndexPage extends React.Component {
    constructor(props) {
      super(props);
  
    }
  
  render(){
  
    return(
        <div>
      <h1 className="main-title">
        Meet Glory<br></br>a Tailor-Made AI
      </h1>
      <iframe  className="youtube-video" src="https://www.youtube.com/embed/g3RS0Dk8oY8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      <iframe  className="youtube-video" src="https://www.youtube.com/embed/QCcG7vEoAws" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

      <h2 className="sub-title">Available, everywhere</h2>
      <p className="sub-text">Where you are and wherever you go.</p>
      <img className="conf_logo" src="/img/meets.png" alt="Google Meets logo" />
      <img className="conf_logo" src="/img/zoom.png" alt="Image 6" />
      <img className="conf_logo" src="/img/teams.png" alt="Image 6" />
      <img className="conf_logo" src="/img/whatsapp.png" alt="Google Meets logo" />
      <img className="conf_logo" src="/img/telegram.png" alt="Image 6" />
      <img className="conf_logo" src="/img/slack.png" alt="Image 6" />
      <img className="conf_logo" src="/img/mail.webp" alt="Image 6" />

      <h2 className="sub-title">Reads, everything</h2>
      <p className="sub-text">Fluent in formats, remembers for you.</p>
      <img className="conf_logo" src="/img/excel.png" alt="Google Meets logo" />
      <img className="conf_logo" src="/img/word.png" alt="Image 6" />
      <img className="conf_logo" src="/img/powerpoint.png" alt="Image 6" />
      <img className="conf_logo" src="/img/pdf.png" alt="Image 6" />
      <img className="conf_logo" src="/img/csv.png" alt="Google Meets logo" />
      <img className="conf_logo" src="/img/txt.png" alt="Image 6" />
      <h2 className="sub-title">Custom</h2>
      <p className="sub-text"> Talks 140 languages, with 450 different accents</p>
      {/* <img className="voice" src="/img/language.png" alt="Image 6" /> */}
        <img className="voice" src="/img/voice.png" alt="Image 6" />

{/* 
      <h2 className="sub-title">ABOUT</h2> */}
      {/* <div className="about-section">
        <p>
          At Gosk.ai, we understand the power and potential of Artificial
          Intelligence in shaping the future of businesses worldwide. Our
          mission is to provide our clients with tailor-made AI agents that not
          only streamline operations but also elevate customer experiences to
          new heights.
        </p>
        <p>
          Our team specializes in harnessing the power of
          cutting-edge AI technologies to create customized AI Staff for a wide
          range of industries. We believe that every business has unique needs
          and challenges, which is why we work closely with our clients to
          develop bespoke solutions that drive innovation, boost productivity,
          and maximize their competitive advantage.
        </p>
        <p>
          As a trusted AI partner, we are committed to staying at the forefront
          of AI advancements, ensuring that our clients receive the most
          up-to-date and efficient tools to succeed in an increasingly digital
          world. Our comprehensive suite of services includes AI chatbot
          development, virtual assistants, data analysis, and AI-driven process
          automation, among others.
        </p>
        
      <img className="partner" src="/img/microsoft.png" alt="Image 6" />
      {/* <h2 className="sub-title2">
        <a href="/signup">
          Join the Beta
        </a>
        
      </h2> */} 
      <br></br><br></br><br></br><br></br><br></br>
    </div>
    )
  }
  
  }
  
  
  export default withLayout(IndexPage);




