import React from "react";
import { getAuth, signOut } from "firebase/auth";
import firebaseConfig from "../firebase.config";
import firebase from "firebase/compat/app";


firebase.initializeApp(firebaseConfig);

const sessionid = localStorage.getItem("userUID");
const auth = getAuth();



class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.IsLoggedIn = this.IsLoggedIn.bind(this);
  }

  signout() {
    signOut(auth)
      .then(() => {
        console.log("Sign-out successful");
        window.location.reload();
      })
      .catch((error) => {
        console.log("error");
      });
  }

  IsLoggedIn() {
    if (!sessionid) {
      return (
        <div>
          <a href="/signup">Register</a>&nbsp;&nbsp;
          <a href="/signin">login</a>
        </div>
      );
    } else {
      return (
        <div>
          <a href="/admin">admin</a>&nbsp;&nbsp;
          <a href="#" onClick={() => this.signout()}>
            logout
          </a>
        </div>
      );
    }
  }



  render() {
    return (
      <div>
        <header className="banner">
          <a href="/">
            <img src="/img/logo.png" alt="Banner Image" />{" "}
          </a>
          <div>
            <this.IsLoggedIn />
          </div>
        </header>
      </div>
    );
  }
}

export default IndexPage;
