import React from "react";
import { withLayout } from "../wrappers/layout";
import firebaseConfig from "../firebase.config";
import firebase from "firebase/compat/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  signInWithRedirect,
  getAdditionalUserInfo,
} from "firebase/auth";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Divider } from "@mui/material";
import swal from "sweetalert";

firebase.initializeApp(firebaseConfig);

const style = {
    width: '100%',
    marginTop: '15px',
  };



class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.continuewithgoogle = this.continuewithgoogle.bind(this);
    this.createProfile = this.createProfile.bind(this);
    this.SigninEmail = this.SigninEmail.bind(this);
  }

//   handleSubmit = (event) => {
//     event.preventDefault();
//     const data = new FormData(event.currentTarget);
//     console.log({
//       email: data.get("email"),
//       password: data.get("password"),
//     });
//   };

    SigninEmail(e) {
    e.preventDefault()
    const data = new FormData(e.currentTarget);
     firebase.auth().signInWithEmailAndPassword(data.get('email'),data.get('password'))
     .then((userCredential) => {
       // Signed in
      //  var userid = userCredential.useruid;
      //  console.log(userid)
      swal("Logged in sucessfully").then(()=>{
        return (window.location.href = "/admin");
      })
     })
     .catch((error) => {
       var errorCode = error.code;
       var errorMessage = error.message;
       swal(errorCode)
     });
  }




  continuewithgoogle() {
    const provider = new GoogleAuthProvider()
    const auth = getAuth();
    // signInWithRedirect(auth, provider);
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const newuser = getAdditionalUserInfo(result).isNewUser;
        console.log(newuser);
        // The signed-in user info.
        const user = result.user;
        if (newuser) {
            this.createProfile(user.uid, user.email);
          } else {
            return (window.location.href = "/admin");
          }
        //console.log(user);
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }

  createProfile = (useruid, email) => {
    try {
      firebase.firestore().collection("/users").doc(useruid).set({
        name: "",
        whatsapp: "",
        email: email,
        uid: useruid,
        wsp_group: "",
        wsp_token: useruid.slice(15),
        beta_user: false,
        group_size: 200,
        language: "English",
      }).then(()=>{
        swal("Account Created!").then(() => {
          window.location.href = "/admin";
        });
        // window.location.href = "/admin";
      });
    } catch (error) {
      console.log("Register failed with exception:", { error });
    }
  };

  signout() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("Sign-out successful");
        window.location.reload();
      })
      .catch((error) => {
        console.log("error");
      });
  }

  render() {
    //console.log(localStorage.getItem("userUID"));
    return (
      <div>
        <div className="signin">
          <Container component="main" maxWidth="sm">
            <Box
              sx={{
                boxShadow: 3,
                borderRadius: 2,
                backgroundColor: 'white',
                px: 4,
                py: 2,
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >

            <img className="signgoogle" onClick={()=>this.continuewithgoogle()} src='/img/google.png'></img>
            <Divider sx={style}>or </Divider>
              <Box
                component="form"
                onSubmit={this.SigninEmail}
                noValidate
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              </Box>
              </Box>
          </Container>
        </div>
      </div>
    );
  }
}

export default withLayout(SignIn);
