import "./App.css";
import React, { useEffect } from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import IndexPage from "./pages";
import SignIn from "./pages/signin";
import Admin from "./pages/admin";
import SignUp from "./pages/signup";

const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    localStorage.setItem("userUID", user.uid);
  } else {
    localStorage.removeItem("userUID");
  }
});


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
