import React from "react";
import { withLayout } from "../wrappers/layout";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { doc, writeBatch } from "firebase/firestore";
import swal from "sweetalert";
import firebaseConfig from "../firebase.config";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { ImportExport } from "@mui/icons-material";
import { getAuth } from "firebase/auth";

import axios from "axios";

const sessionid = localStorage.getItem("userUID");

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

class Admin extends React.Component {
  constructor(props) {
    super(props);

    this.handlewsp = this.handlewsp.bind(this);
    this.handlename = this.handlename.bind(this);
    this.handlelanguage = this.handlelanguage.bind(this);
    this.handlegroupsize = this.handlegroupsize.bind(this);
    this.LabTabs = this.LabTabs.bind(this);
    this.setUserBio = this.setUserBio.bind(this);
    this.ControlledAccordions = this.ControlledAccordions.bind(this);
    this.deletefilelist = this.deletefilelist.bind(this);
    this.deletefile = this.deletefile.bind(this);
    this.fetchUserFiles = this.fetchUserFiles.bind(this);

    this.state = {
      counter: 0,
      tab: "1",
      expanded: false,
      files: [],
    };
  }

  handleChangeTab = (event, newValue) => {
    this.setState({ tab: newValue });
  };
  handleChangeAccordion = (panel) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  LabTabs() {
    return (
      <Box sx={{ maxWidth: "100%", typography: "body1" }}>
        <TabContext value={this.state.tab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={this.handleChangeTab} aria-label="Tabs" centered>
              <Tab label="Account" value="1" />
              <Tab label="My Files" value="2" />
              <Tab label="Linked Accounts" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">{this.admin_tab()}</TabPanel>
          <TabPanel value="2">{this.ControlledAccordions()}</TabPanel>
          <TabPanel value="3">{this.linked_accounts()}</TabPanel>
        </TabContext>
      </Box>
    );
  }

  ControlledAccordions() {
    return (
      <div>
        <Accordion
          expanded={this.state.expanded === "panel1"}
          onChange={this.handleChangeAccordion("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "100%", flexShrink: 0 }}>
              Videocall Summaries
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Example: "What were the action points for each speaker on the
              videocall of 29 of February?"
            </Typography>
            <this.deletefilelist category="videocalls" />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={this.state.expanded === "panel2"}
          onChange={this.handleChangeAccordion("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "100%", flexShrink: 0 }}>Work</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Example: "Check in my work files how was the marketing ads
              campaign last week"
            </Typography>
            <this.deletefilelist category="work" />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={this.state.expanded === "panel5"}
          onChange={this.handleChangeAccordion("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ width: "100%", flexShrink: 0 }}>
              Personal
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Example: "Check in my personal files how much did we spent in
              furniture for the house renovation"
            </Typography>
            <this.deletefilelist category="personal" />
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }

  admin_tab() {
    return (
      <FormGroup>
        {/* <img className="badge" src="/img/betatester.png" alt="Image 6" /> */}
        Beta Account Status: {this.state.beta_user ? "Activated" : "Pending"}
        <TextField
          id="standard-basic"
          label="Name"
          variant="standard"
          type="text"
          inputProps={{ maxLength: 35 }}
          value={this.state.name}
          name="name"
          onChange={this.handlename}
        />
        <br></br>
        <TextField
          id="standard-basic"
          label="WhatsApp | Include country code eg: 6512345678"
          variant="standard"
          type="number"
          inputProps={{ maxLength: 35 }}
          value={this.state.whatsapp}
          name="whatsapp"
          onChange={this.handlewsp}
        />
        <br></br>
        WhatsApp Group Activation Token <br></br>(Add Gosk.ai to the group and
        paste this token to activate summarization | 1 group per account)
        <br></br>
        <b>{this.state.wsp_token}</b>
        <br></br>
        <FormControl>
          Summarize group conversations every:
          <RadioGroup
            style={{ marginRight: "auto", marginLeft: "auto" }}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={this.state.group_size}
            onChange={this.handlegroupsize}
          >
            <FormControlLabel
              value="200"
              control={<Radio />}
              label="~20 Messages"
            />
            <FormControlLabel
              value="500"
              control={<Radio />}
              label="~100 Messages"
            />
            <FormControlLabel
              value="1500"
              control={<Radio />}
              label="~150 Messages"
            />
            <FormControlLabel
              value="Daily"
              disabled
              control={<Radio />}
              label="Daily at 6pm [Phase 2]"
            />
          </RadioGroup>
        </FormControl>
        <br></br>
        <FormControl>
          Language
          <RadioGroup
            style={{ marginRight: "auto", marginLeft: "auto" }}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={this.state.language}
            onChange={this.handlelanguage}
          >
            <FormControlLabel
              value="English"
              control={<Radio />}
              label="English"
            />
            <FormControlLabel
              disabled
              value="Spanish"
              control={<Radio />}
              label="Español [Fase 2]"
            />
          </RadioGroup>
        </FormControl>
        <br></br>
        <Button variant="contained" onClick={this.setUserBio}>
          save
        </Button>
      </FormGroup>
    );
  }

  linked_accounts() {
    return (
      <div>
        <FormControl>
          <FormControlLabel
            checked={this.state.whatsapp.length > 0}
            control={<Switch />}
            label="Whatsapp"
          />
          <FormControlLabel
            disabled
            control={<Switch />}
            label="Telegram (Phase 2)"
          />
          <FormControlLabel
            disabled
            control={<Switch />}
            label="Twitter (Phase 2)"
          />
          <FormControlLabel
            disabled
            control={<Switch />}
            label="Slack (Phase 2)"
          />
          <FormControlLabel
            disabled
            control={<Switch />}
            label="Jira (Phase 2)"
          />
          <FormControlLabel
            disabled
            control={<Switch />}
            label="HubSpot (Phase 2)"
          />
          <FormControlLabel
            disabled
            control={<Switch />}
            label="Salesforce (Phase 2)"
          />
        </FormControl>
      </div>
    );
  }

  componentDidMount() {
    this.check_user_exists(sessionid);
  }

  updateCount() {
    this.setState((prevstate) => ({ counter: prevstate.counter + 1 }));
  }

  createProfile = (useruid) => {
    try {
      firebase
        .firestore()
        .collection("/users")
        .doc(useruid)
        .set({
          name: "",
          whatsapp: "",
          email: "",
          uid: useruid,
          wsp_group: "",
          wsp_token: useruid.slice(15),
          beta_user: false,
          group_size: 200,
          language: "English",
        })
        .then(() => {
          window.location.href = "/admin";
        });
    } catch (error) {
      console.log("Register failed with exception:", { error });
    }
  };

  handlewsp(e) {
    this.setState({ whatsapp: e.target.value });
    //console.log(e.target.value);
  }
  handlename(e) {
    this.setState({ name: e.target.value });
    //console.log(e.target.value);
  }
  handlelanguage(e) {
    this.setState({ language: e.target.value });
    //console.log(e.target.value);
  }

  handlegroupsize(e) {
    this.setState({ group_size: e.target.value });
    //console.log(e.target.value);
  }

  handleChange(e) {
    e.preventDefault();
    var value = e.target.value;
    var name = e.target.name;
    this.setState((state) => (name = value));
  }

  check_user_exists(sessionid) {
    const usersRef = db.collection("users").doc(sessionid);

    usersRef.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        usersRef.onSnapshot((doc) => {
          this.fetchUserData();
          this.fetchUserFiles(sessionid);
        });
      } else {
        this.createProfile(sessionid)
      }
    });
  }

  fetchUserData() {
    //const username = useParams();
    db.collection("users")
      // .where("username", "==", username)
      .where(firebase.firestore.FieldPath.documentId(), "==", sessionid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((element) => {
          //console.log(element.data());
          var data = element.data();
          this.setState({ whatsapp: data.whatsapp });
          this.setState({ beta_user: data.beta_user });
          this.setState({ language: data.language });
          this.setState({ name: data.name });
          this.setState({ wsp_token: data.wsp_token });
          this.setState({ useruid: data.uid });
          this.setState({ group_size: data.group_size });
          this.updateCount();
        });
      });
  }

  fetchUserFiles(sessionid) {
    var temparray = [];
    db.collection("files")
      .where("owner", "==", sessionid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(
          (element) => {
            var filesdata = element.data();
            filesdata.fileid = element.id;
            temparray.push(filesdata);
            ////console.log(element.data())
            //setPortfolioData((arr) => [...arr, portfoliodata]);
            //setNftData(nftdata);
          },
          //console.log("portfolio data done"),
          this.setState({ files: temparray }),
          this.updateCount()
        );
      });
  }

  deletefile(file, filehash, category, useruid) {
    swal("Are you sure you want to delete this file?", {
      buttons: {
        cancel: "Cancel",
        catch: {
          text: "Delete",
          value: "getpremium",
        },
      },
    }).then((value) => {
      switch (value) {
        case "getpremium":
          var data = {
            file: file,
            filehash: filehash,
            category: category,
            useruid: useruid,
          };
          var self = this;
          axios({
            url: "http://4.194.109.25:3001/deletefile",
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          })
            .then(function (response) {
              // handle success
              // console.log(response),
              self.fetchUserFiles(self.state.useruid);
            })
            .catch(function (error) {
              // handle error
              console.log(error);
            });
          break;
        default:
          break;
        // return(this.renderTheme(this.state.originaltheme))
        // console.log("rendered theme");
      }
    });
  }

  deletefilelist(props) {
    const filtered = this.state.files.filter(
      (a) => a.category == props.category
    );
    return (
      <div className="list_files">
        {filtered.map((item, i) => (
          <div key={i} className="file_individual" title={item.name}>
            <img className="file_icon" src="/img/pdf.png"></img>
            <br></br>
            {item.name}
            <br></br>
            <a
              href="#"
              onClick={() =>
                this.deletefile(
                  item.fileid,
                  item.file_hash,
                  item.category,
                  sessionid
                )
              }
            >
              <b>Delete</b>
            </a>
          </div>
        ))}
      </div>
    );
  }

  setUserBio(event) {
    event.preventDefault();
    // this.modifyTechStack();
    db.collection("users").doc(this.state.useruid).update({
      name: this.state.name,
      language: this.state.language,
      whatsapp: this.state.whatsapp,
      group_size: this.state.group_size,
    });
    const dbRef = doc(db, "users", this.state.useruid);
    const batch = writeBatch(db);
    batch
      .commit()
      .then(function () {
        //console.log("Document successfully written!");
        swal("Changes Done!");
      })
      .catch(function (error) {
        //console.error("Error writing document: ", error);
        alert("Error, try again!");
      });
  }

  render() {
    if (!sessionid) {
      return (window.location.href = "/");
    } else if (sessionid && this.state.counter > 1) {
      // const finance_files = this.state.files.filter(
      //   (a) => a.category == 'company'
      // );
      // {this.state.files.map((item) => (
      //   console.log(item.name)
      // ))}

      return (
        <div>
          <h2 className="sub-title3">Manage your account</h2>
          <div className="manage-account">
            <this.LabTabs />
          </div>
        </div>
      );
    }
  }
}

export default withLayout(Admin);
